import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import Crypto from "../components/Crypto/Crypto";
import config from "../../data/SiteConfig";

class CryptoPage extends Component {
  render() {
    return (
      <Layout>
        <div className="crypto-container">
          <Helmet title={`Crypto | ${config.siteTitle}`} />
          <Crypto />
        </div>
      </Layout>
    );
  }
}

export default CryptoPage;
