import React, { Component } from "react";
const config = require("../../../data/SiteConfig")

class SimpleStacked extends Component {
  render() {
    return (
<div className="bg-white">
  <div className="max-w-screen-xl mx-auto pt-8">
    <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
      <span className="text-teal-400">{config.emailSubscribeDescriptionText}</span>
    </h2>

    <form className="sm:flex pt-4" name="newsletter" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action={config.sendySubscribeUrl} accept-charset="utf-8">
      <input aria-label="Email address" name="email" type="email" required className="appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:max-w-xs" placeholder="Enter your email" />
      <input type="hidden" name="form-name" value="newsletter" />

            <div className="hidden">
                <label for="hp">HP</label><br/>
                <input type="text" name="hp" id="hp"/>
            </div>
            <input type="hidden" name="list" value={config.sendyListID}/>
            <input type="hidden" name="subform" value="yes"/>
            <input type="hidden" name="gdpr" value="yes"/>

      <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-teal-400 hover:bg-teal-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
          {config.emailSubscribeButtonText}
        </button>
      </div>
    </form>
    
  </div>
</div>


    );
  }
}

export default SimpleStacked;
