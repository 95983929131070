import React, { Component } from "react";
import SimpleStacked from "../../components/NewsletterSections/SimpleStacked";


class Crypto extends Component {
  render() {
    return (
        <div>

            <div className="py-12 bg-white overflow-hidden">
            <div className="relative mx-auto pl-4 pr-4">
                <div className="relative">
                <h3 className="text-left text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    Crypto Prices
                </h3>

                </div>

                <div className="relative mt-8">
                <div className="relative">
                    <div className="mt-3 text-lg leading-7 text-gray-900">
                    Launching soon. In the meantime, subscribe to our free market and business newsletter.
                    </div>
                </div>
                </div>                

                <SimpleStacked />


            </div>
            </div>
            
        </div>
    );
  }
}

export default Crypto;
